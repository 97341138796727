import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { error_msg } from '../../shared/constants/consts';

@Injectable()
export class DateComparer {
  constructor() {}
  compareDate(control: AbstractControl) : ValidationErrors | null  {
    const from_date = control.get('from_date').value;
    const to_date = control.get('to_date').value;

    let fromDateObject;
    let toDateObject;

    if (from_date) {
      fromDateObject = new Date(
        from_date.year,
        from_date.month,
        from_date.day
      );
    }

    if (to_date) {
      toDateObject = new Date(
        to_date.year,
        to_date.month,
        to_date.day
      );
    }

    if (toDateObject < fromDateObject){
      return { passError: { msg: error_msg.DATE_COMPARER } };
    } else {
      return null;
    }
  }
}
