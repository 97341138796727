<main class="login-page d-flex align-items-center justify-content-center">
  <div class="login-wrap shadow_body">
    <div class="login-header d-flex align-items-center justify-content-center">
      <img src="assets/images/logo.svg" alt="logo" class="img-fluid">
    </div>
    <div class="login-field">
      <form name="form" [formGroup]="resetForm" class="needs-validation"
      [ngClass]="{ 'was-validated': resetForm.touched && !resetForm.valid }" novalidate autocomplete="off">
        <div class="form-group">
          <input appInputFocus formControlName="password" (change)="password.setValue($event.target.value.trim())"
                 type="password" placeholder="Create New Password" class="form-control" required="" />

                 <div *ngIf="password.touched && !password.valid && password.errors?.required" class="error">
                  Please enter password.</div>
          <div *ngIf="password.dirty && password.errors?.minlength" class="error">
            {{errorMsg.PASSWORD_MIN_LENGTH(6)}}</div>
          <div *ngIf="password.dirty && password.errors?.maxlength" class="error">
            {{errorMsg.PASSWORD_MAX_LENGTH(12)}}</div>

        </div>
        <div class="form-group">
          <input type="password" appInputFocus (change)="confirmPassword.setValue($event.target.value.trim())"
                 formControlName="confirmPassword" placeholder="Confirm New Password" class="form-control"
                 required="" />

          <div *ngIf="confirmPassword.touched && !confirmPassword.valid && confirmPassword.errors?.required"
          class="error">{{errorMsg.CONFIRM_PASSWORD_REQUIRED}}</div>
          <div *ngIf="confirmPassword.dirty && confirmPassword.errors?.minlength" class="error">
            {{errorMsg.PASSWORD_MIN_LENGTH(6)}}</div>
          <div *ngIf="confirmPassword.dirty && confirmPassword.errors?.maxlength" class="error">
            {{errorMsg.PASSWORD_MAX_LENGTH(12)}}</div>
          <div class="error" *ngIf="resetForm.hasError('passError')">
            {{resetForm.getError('passError').msg}}
          </div>
        </div>
        <div class="form-group text-center">
          <button type="submit" class="btn btn-secondary text-uppercase ripple-effect-dark"
                  (click)="resetPassword()">Submit
            <i class="fa fa-spin fa-spinner" *ngIf='loading'></i>
          </button>
        </div>
      </form>
    </div>
    <div class="login-footer back">
      <a class="ripple-effect text-uppercase" [routerLink]="['/']"><i class="fa fa-long-arrow-left"></i> LOGIN </a>
    </div>
  </div>
</main>
