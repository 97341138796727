import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HeaderService {
  public titleSubject = new Subject();

  constructor() {}

  setTitle(title: string, titleMain: string) {
    this.titleSubject.next({ title, titleMain });
  }
}
