
import { empty as observableEmpty, Observable, TimeoutError, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable, isDevMode } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenManager } from '../app/shared/services/token-manager.service';
import { ToasterService } from '../app/shared/services/toaster.service';
import { notification_msg, default_const } from '../app/shared/constants/consts';
import * as moment from 'moment-timezone';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenManager: TokenManager,
    private toaster: ToasterService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const lang = this.tokenManager.fetchDataByKey('selectedLanguage');
    const selectedLanguage = lang != null ? lang.toLowerCase() : default_const.Default_Language.key.toLowerCase();
    const timeZone = moment.tz.guess() || '';
    let newReq: HttpRequest<any>;
    let head: any = {
      Authorization: 'Bearer ' + this.tokenManager.fetchToken(),
      language: selectedLanguage,
      'timezone': timeZone
    };
    if (!isDevMode()) {
      head = Object.assign(
        {},
        {
          Authorization: 'Bearer ' + this.tokenManager.fetchToken(),
          language: selectedLanguage,
          'timezone': timeZone
        }
      );
    }
    newReq = request.clone({
      setHeaders: head
    });

    return next.handle(newReq).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.toaster.Error('Please login again', 'Session Expired');
        this.tokenManager.deleteToken();
        this.router.navigate(['/login']);
        return observableEmpty();
      }
      return throwError(err);
    }));
  }
}
