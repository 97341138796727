import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NotFoundComponent } from '@app/shared/component/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HeaderService } from '@app/shared/services/header.service';
import { AuthModule } from './auth/auth.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ApiHandler } from '@app/shared/services/api-handler.service';
// import { TokenManager } from '@app/shared/token-manager.service';
import { ManageDrawer } from '@app/shared/services/drawer.service';
import { ToasterService } from '@app/shared/services/toaster.service';
import { TokenInterceptor } from '@app/app.interceptor';
import { TokenManager } from '@app/shared/services/token-manager.service';
import { SharedModule } from '@app/shared/module/shared.module';
import { AuthService } from '@app/auth/services/auth-service.service';
import { AuthGuard } from '@app/auth/services/auth-guard.service';
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    NgbModule,
    ToastrModule.forRoot({
      preventDuplicates: true,

    }),
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    AuthService,
    HeaderService,
    AuthGuard,
    ApiHandler,
    TokenManager,
    ManageDrawer,
    ToasterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
