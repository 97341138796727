import { NgModule } from '@angular/core';

import { OnFocusDirective } from './on-focus.directive';

@NgModule({
    imports: [],
    declarations: [OnFocusDirective],
    providers: [],
    exports: [OnFocusDirective]
})
export class GlobalDirectivesModule { }
