import { Injectable } from '@angular/core';
import { MyDate } from '../../shared/services/date-formatter.service';
import * as moment from 'moment';

@Injectable()
export class UtilityFunctionService {
  constructor() {}

  dateCustomFormatterForTimeAppend(from_date: string, to_date: string, formData: {}) {
    if (from_date && formData[from_date]) {
      const dateData = formData[from_date],
        day = formData[from_date].day < 10 ? '0' + formData[from_date].day : formData[from_date].day,
        month = formData[from_date].month < 10 ? '0' + formData[from_date].month : formData[from_date].month,
        year = formData[from_date].year;

      // formData[from_date] = `${year}-${month}-${day} 00:00:00`;
      formData[from_date] = `${year}-${month}-${day}`;
    }
    if (to_date && formData[to_date]) {
      const dateData = formData[to_date],
        day = formData[to_date].day < 10 ? '0' + formData[to_date].day : formData[to_date].day,
        month = formData[to_date].month < 10 ? '0' + formData[to_date].month : formData[to_date].month,
        year = formData[to_date].year;

      // formData[to_date] = `${year}-${month}-${day} 23:59:59`;
      formData[to_date] = `${year}-${month}-${day}`;
    }
    // console.log("formData", formData);
  }

  getdateCustomFormatter(custom_date: string, formData: {}) {
    if (custom_date && formData[custom_date]) {
      const dateData = formData[custom_date],
        day = formData[custom_date].day < 10 ? '0' + formData[custom_date].day : formData[custom_date].day,
        month = formData[custom_date].month < 10 ? '0' + formData[custom_date].month : formData[custom_date].month,
        year = formData[custom_date].year;

      return `${year}-${month}-${day}`;
    }
  }
  renderDate(date?: string) {
    let dt;
    let ngbDt;
    dt = date ? new MyDate(date) : new MyDate();
    ngbDt = {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate()
    };
    return ngbDt;
  }

  renderTime(time?: any) {
    let ngbDt;
    let timeData;
    timeData = time.split(':');
    if (timeData && timeData.length === 2) {
      ngbDt = { hour: timeData[0], minute: timeData[1], second: 0 };
    } else {
      ngbDt = { hour: 0, minute: 0, second: 0 };
    }

    // console.log('old date', date, 'js date', dt);
    return ngbDt;
  }

  renderTimeFromUTC(date?: any) {
    let ngbDt;
    let timeData;
    // let date = moment.utc().format();
    // console.log(date, "- now in UTC");

    const local = moment
      .utc(date)
      .local()
      .format('HH:mm:ss');
    timeData = local.split(':');
    if (timeData && timeData.length === 3) {
      ngbDt = { hour: parseInt(timeData[0], 10), minute: parseInt(timeData[1], 10), second: 0 };
    } else {
      ngbDt = { hour: 0, minute: 0, second: 0 };
    }
    return ngbDt;
  }
  // getRideStatusCustomName is used for cutom name of ride status
  getPaymentStatusCustomName(currentState): string {
    switch (currentState) {
      case 'failed':
        currentState = 'Failed';
        break;

      case 'success':
        currentState = 'Success';
        break;

      case 'pending':
        currentState = 'Pending';
        break;

      case 'completed':
        currentState = 'Completed';
        break;

      default:
        break;
    }

    return currentState;
  }

  // getRideStatusCustomName is used for cutom class of ride status
  getPaymentStatusClass(currentState: string): string {
    let className;
    switch (currentState) {
      case 'failed':
        className = 'red';
        break;

      case 'success':
        className = 'green';
        break;

      case 'completed':
        className = 'green';
        break;

      default:
        className = 'red';
        break;
    }

    return className;
  }

  getPromoDiscountTypeCustomName(currentState): string {
    switch (currentState) {
      case 'fixed':
        currentState = 'Fixed';
        break;

      case 'percent':
        currentState = 'Percent';
        break;

      case 'percent_max_discount':
        currentState = 'Percent Max Discount';
        break;

      default:
        break;
    }

    return currentState;
  }

  getPromoDiscountTypeCurrencySign(currentState): string {
    switch (currentState) {
      case 'fixed':
        currentState = '$';
        break;

      case 'percent':
        currentState = '%';
        break;

      case 'percent_max_discount':
        currentState = '%';
        break;

      default:
        currentState = '%';
        break;
    }

    return currentState;
  }

  getBookingStatusWithNgClass(status): object {
    let statusResult = {};

    switch (status) {
      case 'completed':
        statusResult = { Key: status, text: 'Completed', class: 'text-success' };
        break;

      case 'confirmed':
        statusResult = { Key: status, text: 'Yet to schedule', class: 'text-success' };
        break;

      case 'cancelled':
        statusResult = { Key: status, text: 'Cancelled', class: 'text-danger' };
        break;

      case 'inprogress':
        statusResult = { Key: status, text: 'In progress', class: 'text-dark' };
        break;

      case 'cancel_inprogress':
        statusResult = { Key: status, text: 'Cancellation in progress', class: 'text-danger' };
        break;

      case 'failed':
        statusResult = { Key: status, text: 'Failed', class: 'text-danger' };
        break;

      default:
        statusResult = { Key: status, text: status, class: 'text-dark' };
        break;
    }

    return statusResult;
  }

  ConversionISODateToDbDate(dateValue, flag = 1) {
    const result = '';

    const dateArray1 = dateValue.split('T');
    if (dateArray1) {
      const dateArray = dateArray1[0].split('-');
      const dateArrayData = {
        year: dateArray[0],
        month: dateArray[1],
        day: dateArray[2]
      };

      if (flag === 1) {
        return dateArray1[0];
      }
    }

    return result;
  }
  dateCustomFormatterForDB(dateData: any) {
    // tslint:disable-next-line:one-variable-per-declaration
    const day = dateData.day < 10 ? '0' + dateData.day : dateData.day,
      month = dateData.month < 10 ? '0' + dateData.month : dateData.month,
      year = dateData.year;

    // formData[to_date] = `${year}-${month}-${day} 23:59:59`;
    return (dateData = `${year}-${month}-${day}`);
  }
}

export function sumObjectsByKey(...objs) {
  return objs.reduce((a, b) => {
    for (const k in b) {
      // if (b.hasOwnProperty(k)) a[k] =  parseFloat(a[k] || 0) + parseFloat(b[k]);
      // for precision of 2 digit
      if (b.hasOwnProperty(k)) {
        const data = (parseFloat(a[k] || 0) + parseFloat(b[k])).toString();
        a[k] = parseFloat(data).toFixed(2);
      }
    }
    return a;
  }, {});
}

