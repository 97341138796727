<!-- Main Content Start -->
<main class="login-page d-flex align-items-center justify-content-center">
  <div class="login-wrap shadow_body">
    <div class="login-header d-flex align-items-center justify-content-center">
      <img src="assets/images/logo.svg" alt="logo" class="img-fluid" />
    </div>
    <div class="login-field">
      <form name="form" [formGroup]="frm" class="needs-validation"
            [ngClass]="{ 'was-validated': frm.touched && !frm.valid }" novalidate autocomplete="off">
        <div class="form-group">
          <!-- <input type="email" placeholder="Email" class="form-control" required="" /> -->

          <input appInputFocus formControlName="username" (change)="username.setValue($event.target.value.trim())"
                 placeholder="Email" type="text" class="form-control"
                 pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" name="username" required />
          <div class="invalid-feedback" *ngIf="
              username.touched && !username.valid && username.errors?.required
            ">
            Please enter email.
          </div>
          <div class="invalid-feedback" *ngIf="username.dirty && username.errors?.pattern">
            Email should be in valid format.
          </div>
        </div>
        <div class="form-group">
          <input appInputFocus (change)="password.setValue($event.target.value.trim())" formControlName="password"
                 placeholder="Password" type="password" class="form-control" name="password" required />

          <div class="invalid-feedback" *ngIf="
              password.touched && !username.valid && password.errors?.required
            ">
            Please enter password.
          </div>
        </div>
        <div class="form-group text-center">
          <button type="submit"  class="btn btn-secondary text-uppercase ripple-effect-dark" [disabled]="loading" (click)="login()">
            Login
            <i class="fa fa-spin fa-spinner" *ngIf='loading'></i>
          </button>
        </div>
      </form>
    </div>
    <div class="login-footer">
      <a href="javascript:void(0);" [routerLink]="['/forgot-password']" class="ripple-effect text-uppercase">FORGOT
        PASSWORD <i class="fa fa-long-arrow-right"></i></a>
    </div>
  </div>
</main>
<!-- Main Content End -->
