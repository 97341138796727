<!-- Main Content Start -->
<main class="login-page d-flex align-items-center justify-content-center">
  <div class="login-wrap shadow_body">
    <div class="login-header d-flex align-items-center justify-content-center">
      <img src="assets/images/logo.svg" alt="logo" class="img-fluid">
    </div>
    <div class="login-field">
      <form [formGroup]='forgetPasswordResetForm' class="needs-validation"
      [ngClass]="{ 'was-validated': forgetPasswordResetForm.touched && !forgetPasswordResetForm.valid }" novalidate autocomplete="off">
        <div class="form-group">
          <input appInputFocus type="text" formControlName="email" type="text" required
                 (change)="email.setValue($event.target.value.trim())" placeholder="Registered Email"
                 class="form-control"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$"  required="" />
          <div *ngIf="email.touched && !email.valid && email.errors?.required" class="invalid-feedback">
            Please enter email.</div>
          <div *ngIf="email.dirty && email.errors?.maxlength" class="invalid-feedback">
            {{errorMsg.EMAIL_MAX_LENGTH(100)}}</div>
          <div *ngIf="email.dirty && email.errors?.pattern" class="invalid-feedback">
            Email should be in valid format.</div>
        </div>
        <div class="form-group text-center">
          <button type="submit" class="btn btn-secondary text-uppercase ripple-effect-dark"
                  (click)="onSubmit()">Submit<i class="fa fa-spin fa-spinner" style="display:none"></i></button>
        </div>
      </form>
    </div>
    <div class="login-footer back">
      <a href="javascript:void(0);" [routerLink]="['/login']" class="ripple-effect text-uppercase"><i
           class="fa fa-long-arrow-left"></i> LOGIN </a>
    </div>
  </div>
</main>
<!-- Main Content End -->
