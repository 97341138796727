import { LoaderComponent } from './../component/loader/loader.component';
// import { PaginationComponent } from "./../component/pagination/pagination.component";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextAreaComponent } from '@app/shared/component/text-area/text-area.component';
import { StarRatingComponent } from '@app/shared/component/star-rating/star-rating.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExportCsvPdfComponent } from '@app/shared/component/export-csv-pdf/export-csv-pdf.component';
import { ExportCsvPdf1Component } from '@app/shared/component/export-csv-pdf/export-csv-pdf1.component';
import { NoDataComponent } from '@app/shared/component/no-data/no-data.component';
import {
  MyDatePipe,
  MyDateTimePipe
} from '@app/shared/services/date-formatter.service';
// import { BarChartComponent } from "@app/shared/component/bar-chart/bar-chart.component";
// import { MorrisJsModule } from "angular-morris-js";
import { DataLoaderComponent } from '@app/shared/component/loader/data.loader.component';
import { UtilityFunctionService } from '@app/shared/services/utility-function.service';
import { DateComparer } from '@app/shared/services/date-comparer.service';
import { OnRouteService } from '@app/shared/services/onroute.services';
import { AdminSidebarComponent } from '@app/shared/component/admin/admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from '@app/shared/component/admin/admin-header/admin-header.component';
import { RouterModule } from '@angular/router';
import { ConfirmationBoxComponent } from '@app/shared/component/confirmation-box/confirmation-box.component';
import { InputFocusDirective } from '../directives/input-focus.directive';
import { NumberOnlyDirective } from '../directives/number.directive';
import { LightboxModule } from 'ngx-lightbox';
import { FilterSectionComponent } from '../component/admin/filter-section/filter-section.component';
import { FilterButtonComponent } from '../component/admin/filter-button/filter-button.component';
import { NotificationModelComponent } from '@app/admin/notification/notification-model/notification-model.component';

// import { MalihuCustomScrollerModule } from "ngx-malihu-scroller";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    LightboxModule    // MorrisJsModule,
    // MalihuCustomScrollerModule
  ],
  declarations: [
    // PaginationComponent,
    LoaderComponent,
    TextAreaComponent,
    StarRatingComponent,
    ExportCsvPdfComponent,
    ExportCsvPdf1Component,
    NoDataComponent,
    MyDatePipe,
    MyDateTimePipe,
    //  BarChartComponent,
    DataLoaderComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,
    ConfirmationBoxComponent,
    InputFocusDirective,
    NumberOnlyDirective,
    FilterSectionComponent,
    FilterButtonComponent,
    NotificationModelComponent
  ],
  exports: [
    // PaginationComponent,
    LoaderComponent,
    TextAreaComponent,
    StarRatingComponent,
    ExportCsvPdfComponent,
    ExportCsvPdf1Component,
    FilterSectionComponent,
    FilterButtonComponent,
    NoDataComponent,
    MyDatePipe,
    MyDateTimePipe,
    // BarChartComponent,
    DataLoaderComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,
    ConfirmationBoxComponent,
    InputFocusDirective,
    NumberOnlyDirective,
    LightboxModule,
    NotificationModelComponent
   //  MalihuCustomScrollerModule
  ],
  providers: [UtilityFunctionService, DateComparer, OnRouteService]
})
export class SharedModule { }
