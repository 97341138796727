import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/component/not-found/not-found.component';
import { AuthGuard } from '@app/auth/services/auth-guard.service';
import { ForgetPasswordComponent } from '@app/auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

const AppRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  },
  // {
  //   path: '',
  //   loadChildren: './admin/admin.module#AdminModule',
  //  // canActivate: [AuthGuard]
  //   // loadChildren: "./admin/admin.module#AdminModule"
  // },
  {
    path: 'login',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'forgot-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'reset-password/:token',
    // loadChildren: './auth/reset-password/reset.module#ResetPasswordModule'
    component: ResetPasswordComponent
  },
  {
    path: '**',
    // component: NotFoundComponent,
    redirectTo: '/admin/dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      enableTracing: false,
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
