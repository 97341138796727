// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


export const encryption_key = 'x90dh!2$bs';

let base_url = '';
base_url = 'https://gps-tracking-api.codiantdev.com/api'; // Codiant Production
// base_url = 'http://localhost:4810/api';

class Endpoints {
  AUTH_BASE = base_url + '/account/admin';
  CMS_BASE = base_url + '/admin';
  KEEP_ME_SAFE = base_url + '/admin';
  I_AM_SAFE = base_url + '/admin';
  NO_COST_FEATURE = base_url + '/account/admin';
  FREE_TRIAL_EXTENSION = base_url + '/account/admin';
  MANAGE_REPORTS = base_url + '/account/admin';
  DASHBOARD_BASE = base_url + '/account/admin/dashboard/detail';
  MODEL_BASE = base_url + '/admin/model';
  REFERAL_SYSTEM_BASE = base_url + '/admin';
  MISCELLANEOUS_BASE = base_url + '';
// /account/admin/dashboard/detail
  DASHBOARD_ENDPOINTS = {

    GET_DASHBOARD_DETAIL: this.DASHBOARD_BASE,


    // Gps

  };

  AUTH_ENDPOINTS = {
    LOG_IN: this.joinPaths(this.AUTH_BASE, 'login'),
    FORGET_PASSWORD: this.joinPaths(this.AUTH_BASE, 'forgot-password'),
    RESET_PASSWORD: this.joinPaths(this.AUTH_BASE, 'reset-password'),
    CHANGE_PASSWORD: this.joinPaths(this.AUTH_BASE, 'change-password'),
    LOGOUT: this.joinPaths(this.AUTH_BASE, 'logout')
  };
  CMS_ENDPOINTS = {
    GET_CMS: this.joinPaths(this.CMS_BASE, 'cms'),
    // UPDATE_CMS: this.joinPaths(this.CMS_BASE, 'cms'),
    UPDATE_CMS: id => this.joinPaths(this.CMS_BASE, 'cms', id),


  };
  KEEP_ME_SAFE_ENDPOINTS = {
    GET_KEEP_ME_SAFE_USER_LIST: this.joinPaths(this.KEEP_ME_SAFE, 'parents'),
    GET_KEEP_ME_SAFE_USER_DETAIL: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/parent/' + id, 'detail'),
    PARENT_CHILD_LIST: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/parent/' + id, 'childs'),
    DELETE_PARENT: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/parent/' + id, 'delete'),
  };
  I_AM_SAFE_ENDPOINTS = {
    GET_I_AM_SAFE_USER_LIST: this.joinPaths(this.I_AM_SAFE, 'childs'),
    GET_I_AM_SAFE_USER_DETAIL: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/child/' + id, 'detail'),
    CHILD_PARENT_LIST: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/child/' + id, 'parents'),
    DELETE_PARENT: (childUserId: any,parentUserId: any) => this.joinPaths(this.KEEP_ME_SAFE + '/child/' + childUserId+'/'+parentUserId, 'delete'),

  };
  NO_COST_FEATURE_ENDPOINTS = {
    GET_NO_COST_FEATURE_LIST: this.joinPaths(this.NO_COST_FEATURE,'no-cost-feature'),
    UPDATE_NO_COST_FEATURE: this.joinPaths(this.NO_COST_FEATURE, 'no-cost-feature/update'),
    // GET_I_AM_SAFE_USER_DETAIL: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/child/' + id, 'detail'),
    // CHILD_PARENT_LIST: (id: any) => this.joinPaths(this.KEEP_ME_SAFE + '/child/' + id, 'parents'),

  };
  FREE_TRIAL_EXTENSION_ENDPOINTS = {
    GET_FREE_TRIAL_EXTENSION_LIST: this.joinPaths(this.FREE_TRIAL_EXTENSION,'free-trial-extension'),
    UPDATE_FREE_TRIAL_EXTENSION: this.joinPaths(this.FREE_TRIAL_EXTENSION, 'free-trial-extension/update'),


  };
  MANAGE_REPORTS_ENDPOINTS = {
    GET_TOTAL_EARNING_LIST: this.joinPaths(this.MANAGE_REPORTS,'totalearnings/list'),
    GET_TOTAL_ACTIVE_SUBSCRIPTION_LIST: this.joinPaths(this.MANAGE_REPORTS,'activesubscriptions/list'),

    // totalearnings/list

  };
  USER_ENDPOINTS = {
    GET_USER_LIST: base_url + '/user',
    GET_USER_DETAIL: (id: any) => this.joinPaths(base_url + '/user', id),
    POST_USER: this.joinPaths(base_url + '/user'),
    UPLOAD_PROFILE_IMAGE_USER: (id: any) =>
      this.joinPaths(base_url + '/user', id, 'upload-profile-image'),
    UPDATE_USER: (id: any) => this.joinPaths(base_url + '/user', id),
    USER_STATUS_UPDATE_URL: (id: any) =>
      this.joinPaths(base_url + '/user', id, 'change-status'),
    GET_STATE_LIST: (id: any) => this.joinPaths(base_url + '/state', id),
    GET_COUNTRY_LIST: base_url + '/country'
  };


  private joinPaths(...params: string[]) {
    const newUrl = params.join('/');
    return newUrl;
  }

  private joinPathsWithQueryParams(...params: any[]) {
    const newUrl = params.join('?');
    return newUrl;
  }
}

export const API = new Endpoints();
